import {GiLetterBomb} from "react-icons/gi";
import {Icon} from "@chakra-ui/icons";
import * as React from "react";
import {useState} from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Textarea
} from "@chakra-ui/react";

type Props = {
    sizes: string[]
}
function Contact({sizes} : Props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    return <>
        <Icon  cursor="pointer" onClick={handleOpenModal} boxSize={sizes} as={GiLetterBomb} ></Icon>

        <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalCloseButton/>
                <ModalBody justifyContent={'center'}>
                    {modalBody()}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}

function modalBody() {
    return <>
        <form action="https://public.herotofu.com/v1/9d6ab3c0-3522-11ee-bc95-d3e7b6afd596" method="post" acceptCharset="UTF-8">
            <FormControl marginBottom={"15px"}>
                <FormLabel htmlFor="name">Ditt namn</FormLabel>
                <Input name="Name" id="name" type="text" required />
            </FormControl>
            <FormControl marginBottom={"15px"}>
                <FormLabel htmlFor="email">Din Epost (valfritt)</FormLabel>
                <Input name="Email" id="email" type="email"  />
            </FormControl>
            <FormControl marginBottom={"15px"}>
                <FormLabel htmlFor="message">Meddelande</FormLabel>
                <Textarea name="Message" id="message" required />
            </FormControl>
            <div>
                <Button type="submit" colorScheme="blue">
                    Skicka
                </Button>
                <div style={{ textIndent: "-99999px", whiteSpace: "nowrap", overflow: "hidden", position: "absolute" }} aria-hidden="true">
                    <Input type="text" name="_gotcha" autoComplete="off" />
                </div>
            </div>
        </form>
    </>
}

export default Contact;
