import {HStack} from "@chakra-ui/react";
import Donations from "./Donations";
import React from "react";
import InfoButton from "./InfoButton";
import Contact from "./Contact";

type Props = {
    updated: Date | undefined
}
function InfoButtons({updated}: Props) {
    const sizes = ['50px','75px'];
    return <>
        <HStack alignItems={'left'}>
            <Donations sizes={sizes}/>
            <InfoButton sizes={sizes} updated={updated}/>
            <Contact sizes={sizes}></Contact>
        </HStack>
    </>
}

export default InfoButtons;
